/** @jsx jsx */
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { jsx, Container, Flex, Box, Heading, Text, Image } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "../components/shared/Link";
import Layout from "../components/Layout";
import Icon from "../components/shared/Icon";
import ScrollRevealBanner from "../components/ScrollRevealBanner";
import SEO from "../components/seo";
import ContentfulBlogPostRichText from "../components/shared/ContentfulBlogPostRichText";
import "./blogPost.css";
import bgImage from '../images/bg-data-hexagons.png';
import SearchComponent from "../components/shared/SearchComponent";
import BlogFooter from "../components/BlogFooter";
import CustomCodeBlock from "../components/shared/CustomCodeBlock";
import GenerateSection from "./generateSections";
import CardWrapper from '../components/shared/CardWrapper';

const shortcodes = { Link, Box, Icon, inlineCode: CustomCodeBlock, pre:CustomCodeBlock, };
function wrapCharacterWithSpan(inputString, targetCharacter, fontWeight) {
  if (!inputString.includes(targetCharacter)) {
    return inputString;
  }

  const outputElements = [];

  for (let i = 0; i < inputString.length; i++) {
    if (inputString[i] === targetCharacter) {
      outputElements.push(<span key={i} sx={{fontFamily: 'system-ui', fontWeight}}>{targetCharacter}</span>);
    } else {
      outputElements.push(inputString[i]);
    }
  }

  return outputElements;
}

export default function PageTemplate({ data }) {
  const pageData = data.contentfulBlogPost;
  const isHidden = !!pageData.isHidden;
  const title = pageData?.title;
  const subheading = pageData?.subheading?.trim() || null;
  const description = pageData?.description || pageData?.postBodyMdx?.childMdx?.frontmatter?.description;
  const featuredImage = pageData?.featuredImage?.gatsbyImageData;
  const featuredImageAltText = pageData?.featuredImageAltText || pageData?.postBodyMdx?.childMdx?.frontmatter?.imageAltText;
  const featuredImageCaption = pageData?.featuredImageCaption || pageData?.postBodyMdx?.childMdx?.frontmatter?.imageCaption;
  const authorTextield = pageData?.author;
  const authorReference = pageData?.authorReference;
  const date = pageData?.date || pageData?.postBodyMdx?.childMdx?.frontmatter?.date;
  const mainContent = pageData?.postBody || pageData?.postBodyMdx?.childMdx?.body;
  const blogFooter = pageData?.blogFooter;
  const blogFooterSubText = blogFooter?.footerNotesSubtext;
  const blogFooterButton = blogFooter?.button;
  const footerEndText = blogFooter?.footerEndText;
  const footerNotes = blogFooter?.footerNotes;
  const seo = pageData?.seoMetadata;
  const sections = pageData?.sections;
  const moreBlogs = data?.allContentfulBlogPost?.edges;

  return (
    <MDXProvider components={shortcodes}>
      <Layout>
        <SEO
          title={seo?.title}
          description={seo?.description}
          ogImage={`https:${seo?.ogImage?.file?.url}`}
          keywords={seo?.keywords}
          fullImagePath={true}
          robots={isHidden ? "noindex, nofollow" : null}
        />
        <Box sx={{ position: 'relative', paddingInline: [10, 'unset'] }} className="blog">
          <Box sx={{ display: ['none', 'none', 'block'] }}>
            <Image src={bgImage} alt="backgroundImage" sx={{ mt: "50px" }} className="background-image" />
          </Box>
          <Container sx={{ paddingInline: ['0', '20px'] }}>
            {!!subheading &&
              <Flex sx={{marginInline: 'auto', padding: ['20px 0 0 0', '40px 0 0 0'], textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Heading as="h3" sx={{fontWeight: 'bold', color: "#2C489E", textTransform: "uppercase", fontSize:[20, 24, 28]}}>{subheading}</Heading>
              </Flex>
            }
            {title &&
              <Flex sx={{ marginInline: 'auto', padding: ['20px 0 20px 0', '60px 0 36px 0'], textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Heading as="h1" sx={{ variant: 'blog.h1' }} >
                  {title}
                  {/*Why GX extended our employee stock option exercise windows*/}
                </Heading>
                {description &&
                    <Heading as="h2" sx={{ variant: 'blog.h2.description' }}>{wrapCharacterWithSpan(description, 'ć', '245')}</Heading>
                }
              </Flex>
            }
            <Flex
              sx={{
                '@media screen and (max-width: 1018px)': {
                  flexDirection: "column",
                  order: 2
                },
                marginBottom: ['50px'],
                justifyContent: 'space-between'
              }}
            >
              <Flex
                sx={{
                  '@media screen and (max-width: 900px)': {
                    maxWidth: "unset",
                    alignItems: 'center',
                  },
                  order: 1,
                  display: "flex",
                  minWidth: "200px",
                  textAlign: 'left',
                  flexDirection: 'column',
                  marginBottom: '20px',
                }}
              >
                <Box>
                {/* Render author reference field. */}
                {authorReference &&
                  <Link to={`/authors/${pageData.authorReference.slug}`}>
                    <Heading as="h5" pb={2} sx={{ variant: 'blog.h5.author' }}>
                      {authorReference?.name}
                    </Heading>
                  </Link>}
                {/*  Render author text field if it has value and author reference is empty. */}
                {authorTextield && !authorReference &&
                  <Heading as="h5" pb={2} sx={{ variant: 'blog.h5.author' }}>
                    {authorTextield}
                  </Heading>}
                  
                </Box>
                {date && <Box sx={{ marginTop: "10px"}} ><Text pb={2} >{date}</Text></Box>}
                <Box sx={{ marginTop: "30px" }}>
                  <ScrollRevealBanner title={title} />
                </Box>
                
              </Flex>
              <Box
                sx={{
                  maxWidth: "825px",
                  px: "10px",
                  '@media screen and (max-width: 1018px)': {
                    maxWidth: "unset"
                  },
                  paddingTop: [10, 0],
                  order: 2,
                }}
                className="blog-content"
              >
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: '36px',
                    lineHeight: 'none'
                  }}
                >
                  <GatsbyImage
                    image={featuredImage}
                    alt={featuredImageAltText}
                  />
                  <caption sx={{ fontStyle: "italic", mt: 2 }}>
                    {featuredImageCaption}
                  </caption>
                </Flex>
                {data.contentfulBlogPost.postType === 'blog-mdx' ?
                  (
                    <MDXRenderer>
                      {mainContent}
                    </MDXRenderer>
                  ) : (
                    <Box>
                      <ContentfulBlogPostRichText richText={mainContent} />
                    </Box>
                  )
                }
                {/* Footer */}
                {blogFooter && <BlogFooter footerNotesSubtext={blogFooterSubText?.footerNotesSubtext}
                  blogFooterButton={blogFooterButton}
                  footerEndText={footerEndText?.footerEndText}
                  footerNotes={footerNotes} />}
              </Box>
              
            </Flex>
          </Container>
          {moreBlogs.length > 0 &&
          <Container sx={{ paddingInline: ['0', '20px'] }}>
            <Flex sx={{ marginInline: 'auto', padding: ['20px 0 0px 0', '60px 0 0px 0'], textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
              <Heading as="h2" sx={{ fontWeight: 'bold' }}>More blogs</Heading>
            </Flex>
            <Box sx={{
              display: 'flex',
              rowGap: "30px",
              listStyle: 'none',
              flexWrap: 'wrap',
              justifyContent: ['center', null, 'space-between'],
              alignItems: 'center',
              padding: 0,
              my: [2, 4],
              '&::after': {
                content: '""',
                flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%']
              }
            }}>
              {moreBlogs.map(({ node: post }) => (
                <Box
                  key={post.id}
                  sx={{
                    flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%'],
                    display: 'flex',
                    margin: '6px 0',
                  }}
                >
                  <CardWrapper
                    item={{
                      heading: post?.frontmatter?.title || post?.title,
                      image: post?.frontmatter?.featuredImage?.childImageSharp || post?.featuredImage,
                      imageAltText: post?.frontmatter?.title || post?.featuredImageAltText,
                      linkUrl: `/blog/${post.slug}`,
                      date: post?.frontmatter?.date || post?.date,
                      author: post?.authorReference?.name || post?.author || `Great Expectations`,
                      description: post?.frontmatter?.description || post?.description,
                      cardHeight: '520px',
                    }}
                    type="Blog"
                  />

                </Box>
              ))}
            </Box>
          </Container>
          }
          {sections && sections.map((section) => (
            <GenerateSection key={section.id} section={section} />
          ))}
          <SearchComponent dataFor="blog" />
        </Box>
      </Layout>
    </MDXProvider>
  );
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String, $tags: [String]) {
    contentfulBlogPost(id: { eq: $id })  {
      id
      __typename
      subheading
      title
      description
      author
      authorReference {
        name
        slug
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImageCaption
      featuredImageAltText
      featuredImage {
        gatsbyImageData(width: 960)
        file {
          url
        }
      }
      isHidden
      seoMetadata {
        ogImage {
          file {
            url
          }
        }
        title
        description
        keywords
      }
      sections {
        __typename
        ... on ContentfulHeroSection {
          ...ContentfulHeroSectionFragment
          id
        }
      }
      postType
      postBodyMdx {
        childMdx {
          excerpt
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            author
            imageAltText
            imageCaption
          }
          body
        }
      }
      postBody {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            gatsbyImageData(width: 960)
            title
            sys {
              type
            }
          }
          ... on ContentfulAction {
            ...ActionFragment
          }
        }
      }
      blogFooter {
        footerNotesSubtext {
          footerNotesSubtext
        }
        footerNotes {
          raw
        }
        footerEndText {
          footerEndText
        }
        button {
          classnameForAnalytics
          actionEvent
          fullWidth
          icon
          iconSize
          id
          style
          label
          url
          form {
            ...FormFragment
          }
          hubspotForm {
            formId
            portalId
            region
            classname
          }
        }
      }
    }
      allContentfulBlogPost(
      filter: {
          id: { ne: $id },
          postType: { eq: "blog" } ,
          metadata: {
              tags: { elemMatch: { contentful_id: { in: $tags } } }
          }
      }
      sort: { fields: date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          description
          title
          author
          authorReference {
            name
            slug
          }
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            gatsbyImageData(placeholder: NONE, quality: 85, layout: FULL_WIDTH)
          }
          featuredImageAltText
        }
      }
      totalCount
    }
  }
`;