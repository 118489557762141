/* eslint-disable react/destructuring-assignment */
/** @jsx jsx */
import {BLOCKS, INLINES, MARKS} from "@contentful/rich-text-types";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import styled from "styled-components";
import {Box, Flex, Heading, jsx, Link, Paragraph, Text} from "theme-ui";
import {GatsbyImage} from "gatsby-plugin-image";
import Highlight, {defaultProps} from "prism-react-renderer";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import theme from "prism-react-renderer/themes/vsDark";
import {CopyButton} from '@contentful/f36-components';
import {get} from "lodash";
import EnhanceMarkup, {REGEX_PATTERN_FOR_ADDING_COLOR_TO_MARKUP} from "./EnhanceMarkup";
import Action from "./Action";

let language = 'python';

const Line = styled.div`
  display: table-row;
  width: 100%;
`;

const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`;

const LineContent = styled.span`
  display: table-cell;
  white-space: pre-wrap;
  word-break: break-word;
`;

// Todo - Below solution only works when no or single style enhancement is added. This can be easily adapted to work with any style enhancement by using a recursive solution
const enhanceMarkup = (children = []) => {
  // flatMap returns a flattened array - very useful
  const mappedChildren = children.flatMap(child => {
    if(!child) {
      return child;
    }
    const { type } = child;
    const mutatedChild = type ? {...child} : [...child];
    const contentArray = mutatedChild.props?.children || mutatedChild;
    for(let i = 0; i < contentArray.length; i++) {
      const content = contentArray[i];
      if(typeof content === 'string' && content.split(REGEX_PATTERN_FOR_ADDING_COLOR_TO_MARKUP).length > 1) {
        if(type) {
          mutatedChild.props.children[i] =  <EnhanceMarkup text={content}/>;
        } else {
          mutatedChild[i] =  <EnhanceMarkup text={content}/>;
        }
      }
      if(typeof content === 'string' && /\[@(\w+)\]/.test(content)) {
        mutatedChild[i] = <section id={content.match(/\[@(\w+)\]/)[1]}>
            <AnchorLink className="blog-anchor-links" to={`#${content.match(/\[@(\w+)\]/)[1]}`}>{content.replace(/\[@(\w+)\]/, "")}</AnchorLink>
          </section>;
       } else if(typeof content === 'string' && /\[\$caption\](.*?)\[\$caption\]/.test(content)) {
          mutatedChild[i] = <div sx={{marginTop: '-25px', color: '#a2a2a2', fontStyle: 'italics', fontSize: "16px"}}>{content.match(/\[\$caption\](.*?)\[\$caption\]/)[1]}</div>;
       }
    }
    
    return mutatedChild;
  });
  
return mappedChildren;
};

/**
 * Renders the embedded entry based on the given type of the entry.
 * @param node Node of the embedded entry to render.
 * @returns {JSX.Element}
 */
const renderInlineEmbeddedEntry = (node) => {
  const typename = get(node, 'data.target.__typename');
  switch (typename) {
    case 'ContentfulAction':
      return <Action {...node.data.target} />;
    default:
      return (
        <Text sx={{fontSize: '10px', color: 'red', paddingLeft: ['20px', '20px']}}>
          Unknown embedded entry type: <code>{typename}</code>
        </Text>
      );
  }
};

const ContentfulBlogPostRichText = ({ richText, h4HeadingColor, textfontSize, fontColor }) => {
  const options = {
    renderText: (text) => text.split("\n").reduce((children, textSegment, index) => [...children, index > 0 && <br/>, textSegment], []),
    renderMark: {
      [MARKS.CODE]: (node) => {
        let isInline = false;
        if (!Array.isArray(node)) return;
        let htmlEle = node.reduce((acc, n) => {
          if (typeof n === "string") {
            if (n.includes("```")) {
              language = n.replace("```", "");
              return acc;
            }
            if (n.startsWith("`") && n.endsWith("`")) {
              isInline = true;
              return n.substring(1, n.length - 1);
            }
            return acc + n;
          }
          if (typeof n === "object" && n.type === "br") {
            return `${acc}\n`;
          }
          return acc;
        }, "");

        if (htmlEle.indexOf("\n") === 0) {
          htmlEle = htmlEle.replace("\n", "");
        }
        const PreElement = isInline ? "span" : "pre";
        return (
          <Highlight
            {...defaultProps}
            code={htmlEle}
            theme={theme}
            language={language}
          >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <PreElement
                className={className}
                sx={{
                  ...style,
                  display: isInline ? "inline-block" : "block",
                  padding: isInline ? "0px 3px" : "10px",
                  fontSize: isInline ? "12px" : "15px",
                  position: "relative",
                  borderRadius: "0.4rem",
                }}
              >
                {!isInline && <CopyButton
                  tooltipProps={{ placement: "bottom", usePortal: true }}
                  value={htmlEle}
                  className="code-copy-button"
                  size="small"
                />}
                <code sx={{ fontSize: "15px", position: "relative" }}>
                  {tokens.map((line, i) => (
                    <Line key={i} {...getLineProps({ line, key: i })}>
                      {!isInline && <LineNo>{i + 1}</LineNo>}
                      <LineContent>
                        {line.map((token, key) => (
                          <span {...getTokenProps({ token, key })} />
                        ))}
                      </LineContent>
                    </Line>
                  ))}
                </code>
              </PreElement>
            )}
          </Highlight>
        );
      },
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { data } = node;
        const { uri } = data;

        if((uri).includes("youtube.com/embed")) {
          return <span><iframe title="Unique Title 002" src={uri} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" width="640" height="360" frameBorder="0" allowFullScreen  className="i-frames"/></span>;
        }
        return (
          <Link
            sx={{ variant: 'blog.link' }}
            href={uri}
            target="_blank"
          >
            {children}
          </Link>
        );


      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading
          as="h1"
          sx={{ variant: 'blog.h1' }}>
          {enhanceMarkup(children)}
        </Heading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading
          as="h2"
          sx={{ variant: 'blog.h2' }}
        >
          {enhanceMarkup(children)}
        </Heading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading
          as="h3"
          sx={{ variant: 'blog.h3' }}
        >
          {enhanceMarkup(children)}
        </Heading>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Heading
          as="h4"
          sx={{ variant: 'blog.h4' }}
          color={h4HeadingColor}
        >
          {enhanceMarkup(children)}
        </Heading>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Heading
          as="h5"
          sx={{ variant: 'blog.h5' }}
        >
          {enhanceMarkup(children)}
        </Heading>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Heading sx={{marginTop:"16px", marginBottom:"8px"}} as="h6">{enhanceMarkup(children)}</Heading>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Paragraph
          sx={{ variant: 'blog.p' }}
        >
          {enhanceMarkup(children)}
        </Paragraph>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li sx={{ variant: 'blog.li' }}>{children}</li>
      ),
      [BLOCKS.TABLE]: (node, children) => (
        <table className="table-rich-text">
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => <tr >{children}</tr>,
      [BLOCKS.TABLE_CELL]: (node, children) => <td sx={{padding: "10px", height: "20px", color: "white !important"}}>{children}</td>,
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <th sx={{background: "#272763", padding: "10px"}}>{children}</th>,
      [BLOCKS.EMBEDDED_ENTRY]: (node) => (
        <Flex sx={{justifyContent: 'center', alignItems: 'center'}}>
          <Box>{renderInlineEmbeddedEntry(node)}</Box>
        </Flex>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node) => renderInlineEmbeddedEntry(node),
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <Flex sx={{justifyContent: "center", alignItems: "center", my: 4}}>
          <GatsbyImage
              image={node.data?.target?.gatsbyImageData}
              alt={node.data?.target?.title}
          />
        </Flex>
      ),
    },
  };

  return renderRichText(richText, options);
};

export default ContentfulBlogPostRichText;
